/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

// .mat-form-field {
//     margin: 40px 0;
//     width: 500px !important;
// }
.mat-toolbar-single-row {
    height: 20px !important;
    background: transparent;
}
.mat-toolbar-single-row button {
    width: 200px;
}
// .button-height{
//     height: 40px !important;
// }
// .mat-form-field {
//     width: 100%;
// }
#uploadFile {
    top: 0px;
    left: 0px;
    width: 100%;    
    z-index: 9;
    opacity: 0;
    // height: 100%;
    cursor: pointer;
    position: absolute;
}
.inventory-grid {
    grid-template-columns: 200px 100px 100px;

    @screen sm {
        grid-template-columns: 200px 100px 100px;
    }

    @screen md {
        grid-template-columns: 200px 100px 100px;
    }

    @screen lg {
        grid-template-columns: 200px 100px 100px;
    }
}


.fill-primary{
    color: #7c9bff;
    // color: #4EAFCB;
}

.fill-red{
    color: #EC8686;
}
.color-black{
    color: black;
}
body.light .mat-form-field-invalid .mat-input-element, body .light .mat-form-field.mat-warn .mat-input-element, body .light .mat-form-field-invalid .mat-input-element {
    caret-color: black;
}


body.light .mat-step-header.cdk-program-focused, body.light .mat-step-header:hover:not([aria-disabled]){
    background-color: transparent !important;
}

// ::ng-deep{
//     .mat-calendar-body-label{
//         // opacity:0,
//         display: none !important
//     }
//     .mat-calendar-body-label[colspan="7"]{
//         display: none !important
//     }
// }

.mat-horizontal-stepper-header-container{
    padding-right: 4rem !important;
    padding-left: 4rem !important;
}

.mat-select-panel{
    min-width: calc(100%) !important;
    margin-top: 45px;
    margin-left: 30px;
    max-width: calc(100%) !important;
}
